import { graphql, Link } from 'gatsby';

import Layout from '../components/Layout/Layout';

import styles from '../styles/pages/404.module.scss';

import logo from '../assets/logo-dark.svg';

export const notFoundPageQuery = graphql`
  query NotFoundPageQuery {
    site {
      siteMetadata {
        name
        tagline
      }
    }
  }
`;

const NotFoundPage = () => {
  return (
    <Layout SEOProps={{ title: 'Not Found' }}>
      <div className={styles.notFound}>
        <img src={logo} alt="Smartpay" width={448} />
        <h1>
          <span className={styles.left}>404</span>
          <span className={styles.right}>ページが見つかりませんでした</span>
        </h1>
        <Link to="/">トップページに戻る</Link>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
